import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {

  admins: Array<any> = [];
  potentialAdmins: Array<any> = [];
  loading: boolean;
  errorString: string;

  constructor(private readonly afs: AngularFirestore, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.listenToAdmins()
    this.listenToPotentialAdmins()
  }

  listenToAdmins() {
    try {
      this.loading = true
      this.afs.collection("admins").snapshotChanges().subscribe((snaps) => {
        this.admins = [];
        this.loading = false
        snaps.forEach(snap => {
          console.log(snap.payload.doc.data())
          var admin = snap.payload.doc.data()
          admin["id"] = snap.payload.doc.id
          this.admins.push(admin)
        })
      })
    } catch (e) {
      this.loading = false
      return console.error(e)
    }
  }

  listenToPotentialAdmins() {
    try {
      this.afs.collection("potentialAdmins").snapshotChanges().subscribe((snaps) => {
        this.potentialAdmins = [];
        snaps.forEach(snap => {
          console.log(snap.payload.doc.data())
          var admin = snap.payload.doc.data()
          admin["id"] = snap.payload.doc.id
          this.potentialAdmins.push(admin)
        })
      })
    } catch (e) {
      return console.error(e)
    }
  }

  async approve(admin) {
    try {
      console.log(admin)
      this.loading = true
      var now = new Date()
      var createPromise = this.afs.doc(`admins/${admin.id}`).set({ email: admin.email, createdAt: now })
      var deletePromise = this.afs.doc(`potentialAdmins/${admin.id}`).delete()
      await Promise.all([createPromise, deletePromise])
      this.loading = false
      this.snackBar.open('管理者としてユーザーを承認しました。新しい管理者は管理画面にアクセスするために一度ログアウトしてログインし直す必要があります。', null, { duration: 8000 })
    } catch (e) {
      this.loading = false
      this.snackBar.open('エラーが起きました。もう一度試してください。', null, { duration: 3000 })
      return console.error(e)
    }
  }

  async delete(admin) {
    try {
      this.loading = true
      await this.afs.doc(`potentialAdmins/${admin.id}`).delete()
      this.loading = false
    } catch (e) {
      this.loading = false
      this.snackBar.open('エラーが起きました。もう一度試してください。', null, { duration: 3000 })
      return console.error(e)
    }
  }

  async deleteAdmin(admin) {
    try {
      console.log(admin)
      if (this.admins.length == 1) {
        this.snackBar.open('管理者は最低一人は必要です。', null, { duration: 3000 })
        return
      }
      this.loading = true
      await this.afs.doc(`admins/${admin.id}`).delete()
      this.loading = false
    } catch (e) {
      this.loading = false
      this.snackBar.open('エラーが起きました。もう一度試してください。', null, { duration: 3000 })
      return console.error(e)
    }
  }

}
