export class Prospect {
    name: string;
    kana: string;
    prefecture: string;
    prefectureOther: string;
    nationality: string;
    gender: string;
    birthYear: string;
    birthMonth: string;
    birthDate: string;
    phone: string;
    email?: string;
    desiredTransferDate: string;
    japaneseLevel: string;
    englishLevel: string;
    prRequestsQuestions: string;
    resume: string;
    photoId: string;
    id?: string;
    visaStatus: string;
    createdAt;
    updatedAt;
}
