import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/take';
import { Prospect } from "../prospect";
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.css']
})
export class ProspectsComponent implements OnInit {

  constructor(private readonly afs: AngularFirestore, public functions: AngularFireFunctions) {
  }

  ngOnInit() {
    this.getProspects()
  }


  prospects: Array<Prospect> = [];
  loading: boolean;
  errorString: string;

  async getCSV() {
    // const sendEther = this.functions.httpsCallable('sendEther')
    // const resObserver = await sendEther({})
    var getCSVPass = this.functions.httpsCallable('getCSVPass');
    var pass = await getCSVPass({}).toPromise()
    console.log("resut", pass)
    window.location.href = `https://us-central1-kjrecruite.cloudfunctions.net/getCSV?pass=${pass}`
  }


  async getProspects() {
    this.prospects = []
    this.loading = true;
    try {
      var query = this.afs.collection("prospects").ref
      var whereQuery;
      if (!whereQuery) whereQuery = query
      var snap = await whereQuery.orderBy("createdAt", "desc").get()
      this.loading = false;
      snap.forEach(doc => {
        var prospect: Prospect = doc.data() as Prospect;
        prospect.id = doc.id
        this.prospects.push(prospect);
      });
    } catch (e) {
      console.error(e)
      this.loading = false;
      this.errorString = e.message
    }
  }


}
