import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/take';
import { Job } from "../job";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../assets/jobBlock.css']
})
export class HomeComponent implements OnInit {

  selectedJobType: string;

  private jobsCollection: AngularFirestoreCollection<Job>;
  jobs: Array<Job> = [];

  constructor(private readonly afs: AngularFirestore) {
  }

  async getJobs() {
    console.log("get jobs")
    var snap = await this.afs.collection("jobs").ref.orderBy("updatedAt", "desc").limit(4).get()
    // console.log("after await", snap)
    snap.forEach(doc => {
      var job:Job = doc.data() as Job;
      job.id = doc.id
      // console.log(job)
      this.jobs.push(job);
    });
  }

  ngOnInit() {
    this.getJobs();
    // console.log("init")
  }

}
