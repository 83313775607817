import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/take';
import { Job } from "../job";

// export interface Job { name: string; location: string; type: string; language: string; employment_type: string; salary: string; }

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css', '../../assets/jobBlock.css', '../../assets/pcJobBlock.css']
})
export class JobsComponent implements OnInit {

  queryLanguage: string;
  queryType: string;
  loading: boolean;

  errorString: String;
  jobs: Array<Job> = [];


  constructor(private readonly afs: AngularFirestore, private route: ActivatedRoute) {
  }

  async getJobs() {
    this.jobs = []
    this.loading= true;
    try {
      var query = this.afs.collection("jobs").ref
      var whereQuery;
      if(this.queryLanguage) whereQuery = query.where("language", "==", this.queryLanguage)
      if(this.queryType) {
        if(whereQuery) whereQuery = whereQuery.where("type", "==", this.queryType)
        else whereQuery = query.where("type", "==", this.queryType)
      }
      if(!whereQuery) whereQuery = query
      var snap = await whereQuery.orderBy("updatedAt", "desc").limit(12).get()
      // console.log("after await", snap)
      this.loading = false;
      snap.forEach(doc => {
        var job: Job = doc.data() as Job;
        job.id = doc.id
        // console.log(job)
        this.jobs.push(job);
      });
    } catch (e) {
      console.error(e)
      this.loading = false;
      this.errorString = e.message
    }
  }


  ngOnInit() {
    this.queryType = this.route.snapshot.paramMap.get('queryType');
    console.log(this.queryType)
    this.getJobs()
  }

}
