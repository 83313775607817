import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { auth, User } from 'firebase/app';
import { finalize } from 'rxjs/operators';
import { Prospect } from "../prospect";
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  password: string;
  userProfile: Prospect = new Prospect();
  validators: Array<FormControl> = [];
  emailValidator: FormControl = new FormControl('', [
    Validators.required,
  ])
  passwordValidator: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ])


  loading: boolean;

  errorMessage: string;

  resumeError: boolean;
  photoIdError: boolean;
  formError: string;
  updatedMessage: string;
  jobKey: string;

  isEditingProfile: boolean;

  agreedToTermsAndConditions: boolean;

  photoId
  resume


  years;
  months
  dates
  prefectures = ["Aichi", "Akita", "Aomori", "Chiba", "Ehime", "Fukui", "Fukuoka", "Fukushima", "Gifu", "Gumma", "Hiroshima", "Hokkaido", "Hyogo", "Ibaraki", "Ishikawa", "Iwate", "Kagawa", "Kagoshima", "Kanagawa", "Kochi", "Kumamoto", "Kyoto", "Mie", "Miyagi", "Miyazaki", "Nagano", "Nagasaki", "Nara", "Niigata", "Oita", "Okayama", "Okinawa", "Osaka", "Saga", "Saitama", "Shiga", "Shimane", "Shizuoka", "Tochigi", "Tokushima", "Tokyo", "Tottori", "Toyama", "Wakayama", "Yamagata", "Yamaguchi", "Yamanashi", "other"];



  user: User;
  constructor(public afAuth: AngularFireAuth, private route: ActivatedRoute, private _ngZone: NgZone, private readonly afs: AngularFirestore, private storage: AngularFireStorage, private router: ActivatedRoute) {
  }

  async getUserProfile() {
    try {
      this.loading = true
      if (!this.user) {
        throw ("user is null")
      }
      var snap = await this.afs.doc(`prospects/${this.user.uid}`).get().toPromise()
      if (snap.exists) {
        this.userProfile = snap.data() as Prospect
        if (!(this.userProfile.prefecture in this.prefectures)) {
          this.userProfile.prefectureOther = this.userProfile.prefecture;
          this.userProfile.prefecture = 'other';
        }
        console.log(this.userProfile)
        this.userProfile.email = this.user.email
      }
    } catch (e) {
      this.loading = false
      return console.error(e)
    }
    this.loading = false
  }

  ngOnInit() {
    this.jobKey = this.route.snapshot.paramMap.get('jobKey');
    this.userProfile.prefecture = "Tokyo"
    this.isEditingProfile = this.router.snapshot.data.isEditingProfile;
    if (this.isEditingProfile) this.loading = true
    for (var i = 0; i < 13; i++) {
      this.validators.push(new FormControl('', [
        Validators.required,
      ]))
    }

    this.monitorAuth()
    this.years = [];
    this.months = [];
    this.dates = [];
    var eighteenYearsAgo = new Date().getFullYear() - 18;
    for (var i = eighteenYearsAgo; i > 1920; i--) {
      this.years.push(i);
    }

    for (var i = 1; i < 13; i++) {
      this.months.push(i)
    }

    for (var i = 1; i < 32; i++) {
      this.dates.push(i)
    }
  }

  async monitorAuth() {
    this.user = await this.afAuth.auth.currentUser
    this.afAuth.auth.onAuthStateChanged(user => {
      this._ngZone.run(() => {
        this.user = user
        if (this.user) this.getUserProfile()
      });
    })
  }

  photoIdSet(event) {
    const input = event.target;
    const that = this;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      // that.photoId = input.files[0];
      that.userProfile.photoId = input.files[0]
      that.photoId = input.files[0]

      reader.readAsDataURL(input.files[0]);
    }
  }

  resumeSet(event) {
    const input = event.target;
    const that = this;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      // that.resume = input.files[0];
      that.userProfile.resume = input.files[0]
      that.resume = input.files[0]

      reader.readAsDataURL(input.files[0]);
    }
  }

  async register() {
    try {
      this.loading = true
      this.photoIdError = null
      this.resumeError = null
      this.updatedMessage = null
      for (var i = 0; i < this.validators.length; i++) {
        this.validators[i].markAsTouched()
      }
      for (var i = 0; i < this.validators.length; i++) {
        if (!this.validators[i].valid) throw (`form ${i} invalid`)
      }
      if (!this.user) {
        this.emailValidator.markAsTouched()
        if (!this.emailValidator.valid) throw ("email invalid")
      }
      if (!this.user) {
        this.passwordValidator.markAsTouched()
        if (!this.passwordValidator.valid) throw ("password invalid")
      }
      if (!this.isEditingProfile) {
        if (!this.userProfile.photoId) {
          this.photoIdError = true
          throw ("photoId invalid")
        }
        if (!this.userProfile.resume) {
          this.resumeError = true
          throw ("resume invalid")
        }
      }
      if (!this.user) {
        await this.afAuth.auth.createUserWithEmailAndPassword(this.userProfile.email, this.password)
        this.user = await this.afAuth.auth.currentUser
      }
      const photoIdFilePath = `prospects/${this.user.uid}/photoId`;
      const resumeFilePath = `prospects/${this.user.uid}/resume`;
      const photoIdRef = this.storage.ref(photoIdFilePath);
      const resumeRef = this.storage.ref(resumeFilePath);
      if (this.photoId) {
        await this.storage.upload(photoIdFilePath, this.photoId)
        var photoIdUrl = await photoIdRef.getDownloadURL().toPromise()
        this.userProfile.photoId = photoIdUrl
      }
      if (this.resume) {
        await this.storage.upload(resumeFilePath, this.resume)
        var resumeUrl = await resumeRef.getDownloadURL().toPromise()
        this.userProfile.resume = resumeUrl
      }
      var now = new Date
      this.userProfile.updatedAt = this.userProfile.updatedAt || now
      this.userProfile.createdAt = this.userProfile.createdAt || now
      this.userProfile.email = this.user.email
      const inputProspectMap = Object.assign({}, this.userProfile);
      if (this.userProfile.prefecture == 'other') {
        inputProspectMap['prefecture'] = this.userProfile.prefectureOther;
      }
      await this.afs.doc(`prospects/${this.user.uid}`).set(inputProspectMap, { merge: true })
      if (this.jobKey) {
        var job = await this.afs.doc(`jobs/${this.jobKey}`).get().toPromise()
        await this.afs.collection(`applications`).add({
          job: job.data(),
          jobKey: this.jobKey,
          userId: this.user.uid,
          userProfile: (Object.assign({}, this.userProfile)),
          at: now,
        })
        this.updatedMessage = "Application sent";
      } else {
        this.updatedMessage = "Input profile complete";
      }
      this.loading = false;
      this.errorMessage = null
    } catch (e) {
      this.loading = false;
      this.errorMessage = "Something went wrong. Please try again."
      console.log(Object.assign({}, this.userProfile))
      return console.error(e)
    }
  }
}
