import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  user: User;
  sendingPasswordResetEmail: boolean;
  sendingPasswordResetEmailError: string;

  loginErrorMessage: string;

  forgotPassword: boolean;

  sentPasswordResetEmail: boolean;

  loading: boolean;
  emailValidator:FormControl = new FormControl('', [
    Validators.required,
  ])
  passwordValidator:FormControl = new FormControl('', [
    Validators.required,
  ])

  constructor(private router: Router, public afAuth: AngularFireAuth, private _ngZone: NgZone, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.monitorAuth()
  }

  async monitorAuth() {
    this.user = await this.afAuth.auth.currentUser
    this.afAuth.auth.onAuthStateChanged(user => {
      this._ngZone.run(() => {
        this.loading = false;
        this.user = user
      });
    })
  }

  async signIn() {
    this.emailValidator.markAsTouched()
    this.passwordValidator.markAsTouched()
    this.loginErrorMessage = null
    this.loading = true;
    if(!this.emailValidator.valid || !this.passwordValidator.valid) {
      this.loading = false;
      return
    }
    try{
      await this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password);
    }catch(e) {
      this.loginErrorMessage = e.message
      this.loading = false;
      return
    }
    this.loading = false;
    this.router.navigate(['/'])
    this.snackBar.open('Welcome back!', null, { duration: 3000 });
  }

  openForgotPassword() {
    this.forgotPassword = !this.forgotPassword;
  }

  async sendPasswordResetEmail() {
    this.sendingPasswordResetEmail = true;
    this.sendingPasswordResetEmailError = null;
    try{
      this.loading = true;
      await this.afAuth.auth.sendPasswordResetEmail(this.email);
      this.sentPasswordResetEmail = true;
    }catch(e) {
      this.loading = false;
      this.sendingPasswordResetEmail = false;
      this.sendingPasswordResetEmailError = "Something went wrong. Please try again.";
      return console.error(e)
    }
  }


  async signOut() {
    await this.afAuth.auth.signOut()
  }


}
