import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule, MatProgressSpinnerModule, MatTableModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatListModule, MatInputModule, MatSelectModule, MatTable } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { JobDetailsComponent } from './job-details/job-details.component';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { PostJobsComponent } from './post-jobs/post-jobs.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FooterComponent } from './footer/footer.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectComponent } from './prospect/prospect.component';
import { AdminsComponent } from './admins/admins.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { ApplicationsComponent } from './applications/applications.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    JobDetailsComponent,
    HomeComponent,
    JobsComponent,
    PostJobsComponent,
    LoginComponent,
    RegisterComponent,
    FooterComponent,
    ProspectsComponent,
    ProspectComponent,
    AdminsComponent,
    RegisterAdminComponent,
    ApplicationsComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'post-jobs', component: PostJobsComponent },
      { path: 'post-jobs/:jobKey', component: PostJobsComponent },
      { path: 'jobs/:queryType', component: JobsComponent },
      { path: 'jobs', component: JobsComponent },
      { path: 'job-detail/:jobKey', component: JobDetailsComponent },
      { path: 'edit-admin', component: ProspectsComponent, data: { isEditingAdmins: true } },
      { path: 'prospects', component: ProspectsComponent },
      { path: 'admins', component: AdminsComponent },
      { path: 'admin-register', component: RegisterAdminComponent },
      { path: 'prospect/:prospectKey', component: ProspectComponent },
      { path: 'apply/:jobKey', component: RegisterComponent },
      { path: 'applications', component: ApplicationsComponent },
      { path: 'edit-profile', component: RegisterComponent, data: { isEditingProfile: true } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
    ]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
