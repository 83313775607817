import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Job } from "../job";
import { MatSnackBar } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
// export interface Job { name: string; location: string; type: string; language: string; employment_type: string; salary: string; image: string; }


@Component({
  selector: 'app-post-jobs',
  templateUrl: './post-jobs.component.html',
  styleUrls: ['./post-jobs.component.css']
})
export class PostJobsComponent implements OnInit {

  name: string;
  location: string;
  type: string;
  language: string;
  japaneseLevel: string = "";
  englishLevel: string = "";
  otherLanguage: string;
  employment_type: string;
  salary: string;
  requirements: string;
  careerLevel: string;
  description: string;
  thumbnailUrl;
  chosenFile;
  job: Job;
  imageError: boolean;
  formError: boolean;
  jobKey: string;
  createdAt;

  prefectures = ["Aichi", "Akita", "Aomori", "Chiba", "Ehime", "Fukui", "Fukuoka", "Fukushima", "Gifu", "Gumma", "Hiroshima", "Hokkaido", "Hyogo", "Ibaraki", "Ishikawa", "Iwate", "Kagawa", "Kagoshima", "Kanagawa", "Kochi", "Kumamoto", "Kyoto", "Mie", "Miyagi", "Miyazaki", "Nagano", "Nagasaki", "Nara", "Niigata", "Oita", "Okayama", "Okinawa", "Osaka", "Saga", "Saitama", "Shiga", "Shimane", "Shizuoka", "Tochigi", "Tokushima", "Tokyo", "Tottori", "Toyama", "Wakayama", "Yamagata", "Yamaguchi", "Yamanashi"];

  uploading: boolean;

  nameValidator: FormControl = new FormControl('', [
    Validators.required,
  ]);
  locationValidator: FormControl = new FormControl('', [
    Validators.required,
  ]);
  languageValidator: FormControl = new FormControl('', [
    Validators.required,
  ]);
  typeValidator: FormControl = new FormControl('', [
    Validators.required,
  ]);
  employmentTypeValidator: FormControl = new FormControl('', [
    Validators.required,
  ]);

  constructor(private readonly afs: AngularFirestore, private route: ActivatedRoute, private storage: AngularFireStorage, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.jobKey = this.route.snapshot.paramMap.get('jobKey');
    console.log("jobKey", this.jobKey)
    if (this.jobKey) this.loadJobData()
  }

  async loadJobData() {
    try {
      this.uploading = true
      var jobSnap = await this.afs.doc(`jobs/${this.jobKey}`).get().toPromise()
      const job = jobSnap.data()
      this.name = job.name
      this.location = job.location
      this.type = job.type
      this.language = job.language
      this.japaneseLevel = job.japaneseLevel
      this.englishLevel = job.englishLevel
      this.otherLanguage = job.otherLanguage
      this.employment_type = job.employment_type
      this.salary = job.salary
      this.requirements = job.requirements
      this.careerLevel = job.careerLevel
      this.description = job.description
      this.thumbnailUrl = job.image
      this.requirements = job.requirements
      this.createdAt = job.createdAt
      this.uploading = false
    } catch (e) {
      console.error(e)
      this.uploading = false
      return
    }
  }


  showThumbnail(event) {
    const input = event.target;
    const that = this;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      that.chosenFile = input.files[0];

      reader.onload = function (e) {
        that.thumbnailUrl = reader.result;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }



  async addJob() {
    this.imageError = false;
    this.formError = false;
    this.nameValidator.markAsTouched()
    this.locationValidator.markAsTouched()
    this.languageValidator.markAsTouched()
    this.typeValidator.markAsTouched()
    this.employmentTypeValidator.markAsTouched()
    if (!this.nameValidator.valid || !this.locationValidator.valid || !this.languageValidator.valid || !this.typeValidator.valid || !this.employmentTypeValidator.valid) {
      this.formError = true;
      return
    }
    if (!this.chosenFile && !this.jobKey) {
      this.imageError = true;
      this.formError = true;
      return
    }
    var id;
    this.uploading = true;
    if (this.jobKey) {
      id = this.jobKey;
    } else {
      id = this.afs.createId();
      const filePath = `jobs/${id}`;
      const ref = this.storage.ref(filePath);
      // const task = await this.storage.upload(filePath, this.chosenFile)
      const metaData = {
        cacheControl: 'public,max-age=3600',
      }
      await this.storage.upload(filePath, this.chosenFile, metaData)
      var downloadUrl = await ref.getDownloadURL().toPromise()
    }
    // this.uploadPercent = task.percentageChanges();
    var now = new Date();
    const job: Job = {
      name: this.name,
      location: this.location || "",
      type: this.type || "",
      language: this.language || "",
      japaneseLevel: this.japaneseLevel || "",
      englishLevel: this.englishLevel || "",
      employment_type: this.employment_type || "",
      salary: this.salary || "",
      image: downloadUrl || this.thumbnailUrl || "",
      createdAt: this.jobKey?this.createdAt:now,
      updatedAt: now,
      careerLevel: this.careerLevel || "",
      description: this.description || "",
      requirements: this.requirements || "",
      otherLanguage: this.otherLanguage || "",
    }

    await this.afs.doc(`jobs/${id}`).set(job)
    this.uploading = false;
    if (!this.jobKey) {
      this.snackBar.open('求人情報を追加しました', null, { duration: 3000 });
      this.name = null;
      this.nameValidator.markAsUntouched()
      this.location = null
      this.locationValidator.markAsUntouched()
      this.type = null
      this.typeValidator.markAsUntouched()
      this.language = null
      this.languageValidator.markAsUntouched()
      this.japaneseLevel = null;
      this.englishLevel = null;
      this.employment_type = null;
      this.employmentTypeValidator.markAsUntouched()
      this.salary = null;
      this.careerLevel = null;
      this.description = null;
      this.requirements = null
      this.otherLanguage = null;
      this.chosenFile = null;
      this.thumbnailUrl = null;
    }else {
      this.snackBar.open('求人情報を更新しました', null, { duration: 3000 });
    }
    // task.snapshotChanges().pipe(
    //   finalize(async () => {
    //     ref.getDownloadURL().subscribe(downloadUrl => {
    //       const job: Job = {
    //         name: this.name,
    //         location: this.location || "",
    //         type: this.type || "",
    //         language: this.language || "",
    //         japaneseLevel: this.japaneseLevel || "",
    //         englishLevel: this.englishLevel || "",
    //         employment_type: this.employment_type || "",
    //         salary: this.salary || "",
    //         image: downloadUrl || "",
    //         createdAt: now,
    //         updatedAt: now,
    //         careerLevel: this.careerLevel || "",
    //         description: this.description || "",
    //         requirements: this.requirements || "",
    //         otherLanguage: this.otherLanguage || "",
    //       };
    //       // console.log(job);
    //       this.afs.doc(`jobs/${id}`).set(job).then(() => {
    //         this.snackBar.open('Successfully Added a New Job', null, { duration: 3000 });
    //         this.name = "";
    //         this.nameValidator.markAsUntouched
    //         this.location = ""
    //         this.locationValidator.markAsUntouched
    //         this.type = ""
    //         this.typeValidator.markAsUntouched
    //         this.language = ""
    //         this.languageValidator.markAsUntouched
    //         this.japaneseLevel = "";
    //         this.englishLevel = "";
    //         this.employment_type = "";
    //         this.salary = "";
    //         this.careerLevel = "";
    //         this.description = "";
    //         this.requirements = ""
    //         this.otherLanguage = "";
    //         this.chosenFile = null;
    //         this.thumbnailUrl = "";
    //       })
    //     })
    //   })
    // ).subscribe()
  }

}
