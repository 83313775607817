import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Prospect } from "../prospect";

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css']
})
export class ProspectComponent implements OnInit {

  prospectKey: string;
  // prospect: Observable<Prospect>;
  prospect: Prospect;
  loading: boolean;

  private prospectDoc: AngularFirestoreDocument<Prospect>;
  constructor(private afs: AngularFirestore, private route: ActivatedRoute) {
    // this.prospectDoc = afs.doc<Prospect>(`prospects/${this.prospectKey}`);
    // this.prospect = this.prospectDoc.valueChanges();
  }

  async getProspect() {
    this.loading = true;
    try {
      var snap = await this.afs.doc(`prospects/${this.prospectKey}`).get().toPromise()
      this.prospect = snap.data() as Prospect
      this.loading = false;
    } catch (e) {
      this.loading = false;
      return console.error("error getProspect", e)
    }
  }

  ngOnInit() {
    this.prospectKey = this.route.snapshot.paramMap.get('prospectKey');
    this.getProspect()
  }

}
