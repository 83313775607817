import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';


@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.css']
})
export class RegisterAdminComponent implements OnInit {

  email: string;
  password: string;
  user: User;
  loading: boolean;
  errorText: string;
  completeText: string;
  emailValidator: FormControl = new FormControl('', [
    Validators.required,
  ])
  passwordValidator: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ])

  constructor(private readonly afs: AngularFirestore, public afAuth: AngularFireAuth, private _ngZone: NgZone) { }

  ngOnInit() {
    this.monitorAuth()
  }

  async monitorAuth() {
    this.user = await this.afAuth.auth.currentUser
    this.afAuth.auth.onAuthStateChanged(user => {
      this._ngZone.run(() => {
        this.loading = false;
        this.user = user
      });
    })
  }

  async register() {
    try {
      this.loading = true;
      this.emailValidator.markAsTouched()
      this.passwordValidator.markAsTouched()
      if (!this.emailValidator.valid || !this.passwordValidator.valid) return
      await this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password)
      this.user = await this.afAuth.auth.currentUser
      var now = new Date()
      await this.afs.doc(`potentialAdmins/${this.user.uid}`).set({
        createdAt: now,
        email: this.email,
      })
      this.loading = false;
      this.completeText = "管理者申請が完了しました。管理者に承認してもらってください。"
    } catch (e) {
      this.loading = false;
      this.errorText = "エラーが起きました。もう一度やり直してください。";
      return console.error(e)
    }
  }

}
