import { Component, NgZone } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';


@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent {

  user: User;
  admin

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private readonly afs: AngularFirestore, public snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver, public afAuth: AngularFireAuth, private _ngZone: NgZone) {
    this.monitorAuth()
  }

  async signOut() {
    await this.afAuth.auth.signOut()
    this.snackBar.open('Signed out successfully', null, { duration: 3000 });
  }

  async monitorAuth() {
    this.user = await this.afAuth.auth.currentUser
    this.afAuth.auth.onAuthStateChanged(user => {
      this._ngZone.run(() => {
        this.user = user
        this.listenAdmin()
      });
    })
  }

  listenAdmin() {
    if(!this.user) return
    this.afs.doc(`admins/${this.user.uid}`).snapshotChanges().subscribe(snap => {
      this.admin = snap.payload.data()
    })
  }
}
