import { Component, OnInit, Input, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { MatSnackBar } from '@angular/material';
import { Router } from "@angular/router";
import { Job } from "../job";

// export interface Job { name: string; location: string; type: string; language: string; employment_type: string; salary: string; }

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css', '../../assets/jobBlock.css', '../../assets/pcJobBlock.css']
})
export class JobDetailsComponent implements OnInit {

  jobKey: string;
  job: Observable<Job>;
  // @Input() job: Job;
  admin
  adminListener
  user: User;
  loading: boolean


  private jobDoc: AngularFirestoreDocument<Job>;
  constructor(private router: Router, private afs: AngularFirestore, public snackBar: MatSnackBar, private route: ActivatedRoute, public afAuth: AngularFireAuth, private _ngZone: NgZone) {
    this.jobKey = this.route.snapshot.paramMap.get('jobKey');
    this.jobDoc = afs.doc<Job>(`jobs/${this.jobKey}`);
    this.job = this.jobDoc.valueChanges();
  }


  ngOnInit() {
    this.monitorAuth()
  }

  async deleteJob() {
    try {
      var res = confirm("本当にこの求人を削除してもいいですか？（すでにこの仕事に応募済みの人の応募データは残ります。）")
      if (!res) return
      this.loading = true
      await this.afs.doc(`jobs/${this.jobKey}`).delete()
      this.snackBar.open('求人を削除いたしました', null, { duration: 3000 })
      this.loading = false
      this.router.navigate(['/'])
    } catch (e) {
      this.loading = false
      this.snackBar.open('削除に失敗しました。時間をおいてもう一度お試しください。', null, { duration: 3000 })
      return console.error(e)
    }
  }

  async monitorAuth() {
    this.user = await this.afAuth.auth.currentUser
    this.afAuth.auth.onAuthStateChanged(user => {
      this._ngZone.run(() => {
        this.user = user
        this.listenAdmin()
      });
    })
  }

  listenAdmin() {
    if (!this.user) return
    this.adminListener = this.afs.doc(`admins/${this.user.uid}`).snapshotChanges().subscribe(snap => {
      this._ngZone.run(() => {
        this.admin = snap.payload.data()
      })
    })
  }

}
