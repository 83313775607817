import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

  applications = []
  loading: boolean

  constructor(private readonly afs: AngularFirestore) { }

  ngOnInit() {
    this.getApplications()
  }

  async getApplications() {
    try {
      this.loading = true;
      var snaps = await this.afs.collection("applications").ref.orderBy("at", "desc").get()
      this.applications = []
      snaps.forEach(snap => {
        this.applications.push(snap.data())
      })
      this.loading = false
    } catch (e) {
      return console.error(e)
    }
  }

}
